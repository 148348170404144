import {request} from './request';


// 产业特色
export function getCyts(){
  return request({
    url:'/api/v1/cyts/getcyts',
  })
}


// 产业年度数据
export function getCyyear(cyid){
  return request({
    url:'/api/v1/cyts/getcyyear?cyid='+cyid,
  })
}


// 企业信息数据
export function getQyxx(cyid){
  return request({
    url:'/api/v1/cyts/getqyxx?cyid='+cyid,
  })
}


// 产业导览数据
export function getCywalk(cyid){
  return request({
    url:'/api/v1/cyts/getcywalk?cyid='+cyid,
  })
}


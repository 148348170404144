<template>

	<div class="module">
		<div class="caption">
			<p>热门信息</p>
		</div>
		
		<!-- <p class="info1">状态：<span>已调节</span></p>
		<div class="hr1"></div> 
		<p class="info2">时间段：<span>2021-02-16</span></p> -->
		
		<!-- <div class="hot1">
			<p class="question">莆田：今年计划建设近2000个5G基站</p>
			<p class="answer">我市日前印发《莆田市加快5G网络建设和产业发展实施方案》,今年全市计划建设近2000个5G基站,覆盖主城区、重点区域、重点应用场所。</p>
		</div>

		<div class="hot2">
			<p class="question">莆田市持续推进电商产业 培育壮大跨境电商</p>
			<p class="answer">据悉，市跨境电商监管中心今年累计操作3173个包裹，实现出口额132万元。疫情发生后，通过积极采取应对措施，于3月18日恢复业务运营，当日操作726个包裹、出口额13.3万元。</p>
		</div> -->

		<ul>
			<li v-for="(item,index) in qaData" :key="index">
				<!-- <div class="hot1"> -->
					<!-- <p class="question">{{ item.question }}</p> -->
					<!-- <p class="answer">{{ item.answer }}</p> -->
				<!-- </div> -->
			</li>
		</ul>

	</div>
  
</template>


<script>
import { getQuestion } from '../../network/yqgh';

export default {

	data() {
		return {
			timer: null,		// 定时器
			qaData: [],		
		};
	},

	mounted() {
		this.init();
		this.timer = setInterval(() => {
       		setTimeout(this.refresh(), 0)	   
   		}, 1000*10)
	},
	beforeDestroy(){
		clearInterval(this.timer);        
		this.timer = null;
	},

	methods: {
		init() {
			this.refresh();
		},

		refresh() {	
			getQuestion("1").then(res=>{
				this.qaData = res.data;
			})
		}
	}

}

</script>


<style scoped>

.info1 {
	
	top: 10px;
	left: 500px;	
	position: absolute;	
	
	font-size: 15px;
	color: rgb(0, 166, 163);

}

.info2 {
	
	top: 10px;
	left: 638px;	
	position: absolute;	
	
	font-size: 15px;
	color: rgb(0, 166, 163);

}

span {	
	color: rgb(255, 255, 255, 0.8);
}

.hr1 {
	
	top: 9px;
	left: 613px;	
	height: 24px;
	position: absolute;	
	
	border-right: 1px solid #174b87;
}


.hot1{
		
	top: 15px;
	left: 10px;
	width: 780px;	
	height: 65px;
	text-align: left;

	position: relative;	
	/* background-color: #16589c; */
	background-color: rgb(20, 50, 76);
	border-bottom: 1px solid rgb(21, 219, 245, 0.8);
}

.hot2{
	
	top: 110px;
	left: 10px;
	width: 780px;
	height: 65px;
	text-align: left;

	position: absolute;	
	/* background-color: #00798b; */
	background-color: rgb(20, 50, 76);
}

.question{
	
	top: 5px;
	left: 20px;	
	position: relative;	
	color: #9BF5ED;	
	
}

.answer{
	
	top: 5px;
	left: 35px;	
	width: 740px;
	/* text-indent: 2em; */
	position: relative;		
	color: rgb(255, 255, 255, 0.8);
} 




</style>
<template>

	<div class="module">
		<div class="caption">
			<p>企业实力</p>
		</div>
		
		<div class="pan1">				
			<div class="pan_chart" ref="chart1">
			</div>
		</div>

		<div class="pan2" ref="pan2" @click="opentc">			
			<ul>
				<li>
					<img src="~@/assets/images/cyts/folder.png" alt="">                            
					<p>知识产权</p>
				</li>
				<li>
					<img src="~@/assets/images/cyts/folder.png" alt="">                             
					<p>共建基地</p>
				</li>
				<li>
					<img src="~@/assets/images/cyts/folder.png" alt="">                              
					<p>研发团队</p>
				</li>
				<li>
					<img src="~@/assets/images/cyts/folder.png" alt="">                            
					<p>企业产品</p>
				</li>
				<li>
					<img src="~@/assets/images/cyts/folder.png" alt="">                             
					<p>团队风采</p>
				</li>
				<li>
					<img src="~@/assets/images/cyts/folder.png" alt="">                              
					<p>企业建设</p>
				</li>
			</ul>   		
		</div>

	</div>
  
</template>


<script>
import * as Echarts from 'echarts';
import { getMedia } from '../../network/media';

export default {
    data() 
	{ 
		return { 
			timer: null,		// 定时器
			chart1: null,			
			qyxx: {},			// 企业信息
		} 	
	}, 
    mounted() 
	{ 
		this.init();
		this.timer = setInterval(() => {
       		setTimeout(this.refresh(), 0)	   
   		}, 1000*10)
	},
	beforeDestroy(){
		clearInterval(this.timer);        
		this.timer = null;
	},

	methods: {

		init() {
			//1.初始化
			this.chart1 = Echarts.init(this.$refs.chart1);
			
			// 产业特色（11）的多媒体资源设置
			getMedia("11").then(res=>{
				this.mediaSetting = res.data[0];	
				// console.log(this.mediaSetting.prefix);
				
			})

			this.refresh();
		},

		refresh() {			
			//2.配置数据
			let option1 = {
				
				radar: {
					// shape: 'circle',
					indicator: [
						{ name: '创新', max: 10000},
						{ name: '销售', max: 10000},
						{ name: '企业能力', max: 10000},
						{ name: '资产增值', max: 10000},
						{ name: '创利', max: 10000}
					]
				},
				series: [{
					name: '预算 vs 开销（Budget vs spending）',
					type: 'radar',
					data: [
						{
							value: [10000, 10000, 10000, 10000, 10000],
							name: '预算分配（Allocated Budget）'
						}
					]
				}]
			};
			
			// 3.传入数据
			this.chart1.setOption(option1);
		},
		
		// 选择企业信息
		selectQyxx(qyxx) {
			this.qyxx = qyxx;
			// console.log("企业信息：" + this.qyxx.id + "; " + this.qyxx.qyname);
						
			if (this.qyxx.image.length > 0) {
				this.$refs.pan2.style.opacity = 1;
			}
			else {
				this.$refs.pan2.style.opacity = 0.5;
			}
		},

		
		// 打开对话框
		opentc() {

			if (this.qyxx.image.length <= 0) {
				return;
			}

            var imagebox = [];
			var arrtc = this.qyxx.image.split(",");
			for (var i=0; i<arrtc.length; i++)
			{
				var tcimage = {};
				tcimage.id = i;
				tcimage.idView = this.mediaSetting.prefix + this.qyxx.qyname + "/" + this.mediaSetting.images + "/" + arrtc[i].trim();

				console.log(tcimage.idView);
				imagebox.push(tcimage);
			}

			this.$parent.openTC(this.qyxx.id, imagebox);
		},
	}
}
</script>


<style scoped>

.pan1 {	
	top: 10px;
	left: 10px;
	width: 290px;
	height: 230px;
	border-radius:10px;
  	position:relative;
	background-color: #152a3a;		
}

.pan2 {	
	top: 35px;
	left: 305px;
	width: 390px;
	height: 260px;
	/* border-radius:10px; */
  	position:absolute;
	/* background-color: #152a3a;	 */
}


.pan_chart {	
	width: 100%;
	height: 100%;
}


.pan2 ul {	
	top: 25px;
	left: 25px;	
	
	width: 390px;
	height: 260px;
	position: absolute;	
	/* background-color: red;	 */
}

.pan2 ul li {		
    position: relative;
    float: left;
    width: 115px;
    height: 115px;
	
	pointer-events: auto;
	cursor: pointer;
}

.pan2 ul li p {	
	font-size: 12px;
	line-height: 28px;
	color: rgb(255, 255, 255, 0.8);
}

</style>
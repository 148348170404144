<template>
  <h1></h1>

	<div class="panSdcy">		
    <SDCY />
	</div>

	<div class="panFlcysj">		
    <FLCYSJ />
	</div>
  
	<div class="panRmxx">		
    <RMXX />
	</div>


	<div class="panmap">
		<!-- <div class="navmanyou">
			<ul>
				<li><p class="currentyq" @click="startWalk">产业导览</p></li>
				<li><p @click="stopWalk">停止导览</p></li>
			</ul>
		</div> -->

		<div class="mapleft"></div>
		<div class="maptop"></div>
		
		<div class="map">
    		<MapCYTS ref="child_map" />
		</div>

		<div class="mapright"></div>
		<div class="mapbottom"></div>
	</div>

	<div>		
    	<DIALOG_TC ref="child_dialog_tc" />
    	<DIALOG_Video ref="child_dialog_video" />
    	<DIALOG_VRImg ref="child_dialog_vrimg" />
    	<DIALOG_VRVideo ref="child_dialog_vrvideo" />
	</div>
  
	<div class="panQyxx">		
    	<QYXX ref="child_qyxx" />
	</div>
  
	<div class="panQysl">		
    	<QYSL ref="child_qysl" />
	</div>
  
	<div class="panLink">		
    	<LINK ref="child_link" />
	</div>

	<div class="panKslj">		
    	<KSLJ ref="child_kslj" />
	</div>

</template>


<script>
import MapCYTS from '@/components/map/MapCyts.vue'
import SDCY from '@/components/cyts/sdcy.vue'
import FLCYSJ from '@/components/cyts/flcysj.vue'
import RMXX from '@/components/cyts/rmxx.vue'
import QYXX from '@/components/cyts/qyxx.vue'
import QYSL from '@/components/cyts/qysl.vue'
import LINK from '@/components/cyts/link.vue'
import KSLJ from '@/components/cyts/kslj.vue'

import DIALOG_TC from '@/components/dialog/dialog_image.vue'
import DIALOG_Video from '@/components/dialog/dialog_video.vue'
import DIALOG_VRImg from '@/components/dialog/dialog_vrimg.vue'
import DIALOG_VRVideo from '@/components/dialog/dialog_vrvideo.vue'

export default {

	components: {
		MapCYTS,
		SDCY,
		FLCYSJ,
		RMXX,
		QYXX,
		QYSL,
		LINK,
		KSLJ,    
		
		DIALOG_TC,
		DIALOG_Video,
		DIALOG_VRImg,
		DIALOG_VRVideo,
	},

	methods: {

		// 选择产业
		selectCY(cyid) {
			console.log("产业：" + cyid);
			this.$refs.child_map.selectCY(cyid);
			this.$refs.child_kslj.selectCY(cyid);

		},
		
		// 选择企业信息
		selectQyxx(qyxx) {
			// console.log("企业信息：" + qyxx.id + "; " + qyxx.qyname);
			this.$refs.child_qyxx.selectQyxx(qyxx);
			this.$refs.child_qysl.selectQyxx(qyxx);
			this.$refs.child_link.selectQyxx(qyxx);
			this.$refs.child_map.selectQyxx(qyxx);
			
		},

		// 打开弹出框
		openTC(cyid, imagebox) {
			this.$refs.child_dialog_tc.open(imagebox);
		},
		openVideo(cyid, vadioURL) {
			this.$refs.child_dialog_video.open(vadioURL);
		},
		openVRImg(cyid, vrURL) {
			this.$refs.child_dialog_vrimg.open(vrURL);
		},
		openVRVideo(cyid, vrvadioURL, autoplay) {
			this.$refs.child_dialog_vrvideo.open(vrvadioURL, autoplay);
		},		
		closeVRVideo() {
			this.$refs.child_dialog_vrvideo.close();
		},

		
        // 开始漫游
        startWalk() {
			this.$refs.child_map.startWalk();
        },
        
        // 停止漫游
        stopWalk() {
			this.$refs.child_map.stopWalk();      
        },

	}

}
</script>


<style scoped>

.panmap {		
	top: 0px;
	left: 582px;
	width: 1465px;
	height: 944px;
	text-align: center;
  	position: relative;  
  	overflow: hidden;
}

.panSdcy {	
	top: 79px;
	left: 30px;
	width: 554px;
	height: 208px;
	text-align: center;
	position: absolute;		
	background: url("~@/assets/images/main/corner1.png") no-repeat right bottom; 
}

.panFlcysj {	
	top: 303px;
	left: 30px;
	width: 554px;
	height: 419px;
	text-align: center;
	position: absolute;		
	background: url("~@/assets/images/main/corner1.png") no-repeat right bottom; 
}

.panRmxx {	
	top: 731px;
	left: 30px;
	width: 554px;
	height: 184px;
	text-align: center;
	position: absolute;			
	background: url("~@/assets/images/main/corner1.png") no-repeat right bottom; 
}

.panQyxx {	
	top: 79px;
	right: 30px;
	width: 554px;
	height: 157px;
	text-align: center;
	position: absolute;		
	background: url("~@/assets/images/main/corner2.png") no-repeat left bottom; 
}

.panQysl {	
	top: 252px;
	right: 30px;
	width: 554px;
	height: 284px;
	text-align: center;
	position: absolute;		
	background: url("~@/assets/images/main/corner2.png") no-repeat left bottom; 
}

.panLink {	
	top: 547px;
	right: 30px;
	width: 554px;
	height: 103px;
	text-align: center;
	position: absolute;		
} 

.panKslj {	
	top: 648px;
	right: 30px;
	width: 554px;
	height: 267px;
	text-align: center;
	position: absolute;			
	background: url("~@/assets/images/main/corner2.png") no-repeat left bottom; 
}


.navmanyou {
    
    z-index: 100;
    
    top: 100px;
    left: 848px;
    width: 453px;
    height: 76px;

    position:absolute;
	background: url("~@/assets/images/qwys/mapmenu.png") no-repeat; 	

}

.navmanyou ul {   
    top: 20px;
    left: 80px; 
    position:absolute;
}

.navmanyou li {    
	float: left;
    width: 150px;
	cursor: pointer;

    text-align: center;
}

.navmanyou li p{    
    margin: 0 10px 0 10px;
    top: 10px;
	font-size: 16px;
	line-height: 30px;	
	text-align: center;

    
    border-radius: 15px;
    background-color: rgb(255, 255, 255, 0.02);	

	color: rgb(255, 255, 255, 0.8);	
}

.navmanyou li .currentyq{    
    background-color: rgb(255, 255, 255, 0.2);	
	color: rgb(255, 255, 255, 0.8);	
}




</style>
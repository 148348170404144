<template>

	<div class="module">
		<div class="caption">
			<p>快速链接</p>
		</div>
		
		<!-- <div class="box3">    
			<p class="p1">企业名称</p>
			<p class="p2">主营产品</p>

			<div class="yq">
				<div index=0 id="yq_col1" @click="click1">
					<p class="row1">{{ name1 }}</p>
					<p class="row2">{{ introduction1 }}</p>
				</div>

				<div index=1 id="yq_col2" class="" @click="click2">
					<p class="row1">{{ name2 }}</p>
					<p class="row2">{{ introduction2 }}</p>
				</div>
				
				<div index=2 id="yq_col3" class="" @click="click3">
					<p class="row1">{{ name3 }}</p>
					<p class="row2">{{ introduction3 }}</p>
				</div>		
				
				<div index=3 id="yq_col4" class="" @click="click4">
					<p class="row1">{{ name4 }}</p>
					<p class="row2">{{ introduction4 }}</p>
				</div>		
			</div>
		</div> -->

	</div>
  
</template>



<script>
import { getQyxx } from '../../network/cyts';

export default {

    data() 
	{ 
		return { 
            qyxxData: [],

			
			tableData: [
				{
					date: '2016-05-03',
					name: 'Tom',
					address: 'No. 189, Grove St, Los Angeles',
				},
				{
					date: '2016-05-02',
					name: 'Tom',
					address: 'No. 189, Grove St, Los Angeles',
				},
				{
					date: '2016-05-04',
					name: 'Tom',
					address: 'No. 189, Grove St, Los Angeles',
				},
				{
					date: '2016-05-01',
					name: 'Tom',
					address: 'No. 189, Grove St, Los Angeles',
				},
			],
		} 	
	}, 
	
	computed: {
		
		name1: function(){
			if (this.qyxxData.length >= 1) {
				return this.qyxxData[0].qyname;
			}
			else {
				return "";
			}
		},
		introduction1: function(){
			if (this.qyxxData.length >= 1) {
				return this.qyxxData[0].products;
			}
			else {
				return "";
			}
		},
		
		name2: function(){
			if (this.qyxxData.length >= 2) {
				return this.qyxxData[1].qyname;
			}
			else {
				return "";
			}
		},
		introduction2: function(){
			if (this.qyxxData.length >= 2) {
				return this.qyxxData[1].products;
			}
			else {
				return "";
			}
		},
		
		name3: function(){
			if (this.qyxxData.length >= 3) {
				return this.qyxxData[2].qyname;
			}
			else {
				return "";
			}
		},
		introduction3: function(){
			if (this.qyxxData.length >= 3) {
				return this.qyxxData[2].products;
			}
			else {
				return "";
			}
		},
		
		name4: function(){
			if (this.qyxxData.length >= 4) {
				return this.qyxxData[3].qyname;
			}
			else {
				return "";
			}
		},
		introduction4: function(){
			if (this.qyxxData.length >= 4) {
				return this.qyxxData[3].products;
			}
			else {
				return "";
			}
		},
	},

	mounted() {
		this.init();
		this.selectCY(1);
	},

	methods: {
		
        // 选择产业特色
        selectCY(cyid) {
			getQyxx(cyid).then(res=>{
				this.qyxxData = res.data;		
			})
        },

		init() {

			var that = this;
			var yqs = document.querySelectorAll(".box3 .yq div")
			for (var i = 0; i<yqs.length; i++) {
				yqs[i].addEventListener('click', function() {
					
					for (var j = 0; j < yqs.length; j++) {
						yqs[j].className = '';
					}
					this.className = 'currentyq';
											
				})
			}

		},

		click1() {
			if (this.qyxxData.length >= 1) {
				this.$parent.selectQyxx(this.qyxxData[0]);
			}
		},
		
		click2() {
			if (this.qyxxData.length >= 2) {
				this.$parent.selectQyxx(this.qyxxData[1]);
			}
		},
		
		click3() {
			if (this.qyxxData.length >= 3) {
				this.$parent.selectQyxx(this.qyxxData[2]);
			}
		},
		
		click4() {
			if (this.qyxxData.length >= 4) {
				this.$parent.selectQyxx(this.qyxxData[3]);
			}
		},


	},

}
</script>



<style scoped>


.box3 {
	
	top: 50px;
	left: 10px;
	width: 780px;
	height: 200px;
	position: absolute;	
    overflow: hidden;
	border-radius:10px;
    border: 1px solid rgb(255, 255, 255, 0.2);
}

.box3 .p1 {	
	top: 0px;
	left: 0px;	
	width: 260px;
	position: absolute;	
	
	font-size: 16px;
  	line-height: 46px;
	color: #9BF5ED;
	background-color: #152A3A;
}
.box3 .p2 {	
	top: 0px;
	left: 260px;	
	width: 519px;
	position: absolute;		
	font-size: 16px;
  	line-height: 46px;
	color: #9BF5ED;
	background-color: #152A3A;
	
	border-left: 1px solid rgb(255, 255, 255, 0.1);
	/* border-right: 1px solid rgb(255, 255, 255, 0.1); */
}


.box3 .yq {	
	
	top: 46px;
	left: 0px;	
	width: 780px;
	height: 200px;
	position: absolute;	
	/* background-color: red; */

	font-size: 14px;
	color: rgb(255, 255, 255);
}

.box3 .currentyq {	
	background-color: rgb(20, 50, 76);
}

.box3 #yq_col1 {	
	top: 0px;	
	left: 0px;	
	width: 780px;
	height: 38px;
	position: absolute;	
	/* background-color: #152A3A; */
	border-top: 1px solid rgb(255, 255, 255, 0.1);
	border-bottom: 1px solid rgb(255, 255, 255, 0.1);
	
	pointer-events: auto;
	cursor: pointer;
}
.box3 #yq_col2 {	
	top: 39px;	
	left: 0px;	
	width: 780px;
	height: 38px;
	position: absolute;		
	border-bottom: 1px solid rgb(255, 255, 255, 0.1);
	
	pointer-events: auto;
	cursor: pointer;
}
.box3 #yq_col3 {	
	top: 78px;	
	left: 0px;	
	width: 780px;
	height: 38px;
	position: absolute;	
	/* background-color: #152A3A; */
	border-bottom: 1px solid rgb(255, 255, 255, 0.1);
	
	pointer-events: auto;
	cursor: pointer;
}
.box3 #yq_col4 {	
	top: 117px;	
	left: 0px;	
	width: 780px;
	height: 38px;
	position: absolute;	
	border-bottom: 1px solid rgb(255, 255, 255, 0.1);
	
	pointer-events: auto;
	cursor: pointer;
}
.box3 #yq_col5 {	
	top: 156px;	
	left: 0px;	
	width: 780px;
	height: 38px;
	position: absolute;	
	/* background-color: #152A3A; */
	/* border-bottom: 1px solid rgb(255, 255, 255, 0.1); */
	
	pointer-events: auto;
	cursor: pointer;
}

.box3 .row1 {	
	top: 0px;	
	left: 0px;	
	width: 260px;
	height: 40px;
	line-height: 40px;
	overflow: hidden;
	position: absolute;	
	border-right: 1px solid rgb(255, 255, 255, 0.1);
}
.box3 .row2 {	
	
	top: 0px;	
	left: 268px;	
	width: 519px;
	height: 40px;
	line-height: 40px;
	position: absolute;	
	text-align: left;
	overflow: hidden;

	/* border-left: 1px solid rgb(255, 255, 255, 0.1); */
}


</style>
<template>

	<div class="module">
		<div class="caption">
			<!-- <p>{{ qyxx.qyname }}</p> -->
			<p>XXXX公司</p>
		</div>
		
		<div class="pan">	
			<!-- <p class="content">{{ qyxx.introduction }}</p> -->
			<p class="content">公司简介</p>
		</div>
	</div>
  
</template>


<script>

export default {

    data() 
	{ 
		return { 
			qyxx: {},
		} 	
	}, 

	methods: {
		
		// 选择企业信息
		selectQyxx(qyxx) {
			this.qyxx = qyxx;						
		},
	}

}

</script>


<style scoped>


.pan {	
	top: 10px;
	left: 10px;
	width: 780px;
	height: 115px;
	border-radius:10px;
  	position:relative;
	background-color: #152a3a;	
}


.content {

	top: 10px;
	left: 10px;
	width: 760px;
	height: 95px;
	position: absolute;	

	overflow: auto;
	font-size: 12px;
	line-height: 18px;
	text-align: left;
	text-indent: 2em;
	color: rgb(255, 255, 255, 0.8);
	
	/* background-color: red; */
}

</style>
<template>

	<div class="module" @click="clickAll">
		<div class="caption">
			<p>产业概况</p>
		</div>
		
		<div class="legend">
			<ul>
				<li>
					<p class="gyl_title"><span class="color_1">■ </span>电子信息</p>
				</li>
				<li>
					<p class="gyl_title"><span class="color_2">■ </span>机械制造</p>
				</li>
				<li>
					<p class="gyl_title"><span class="color_3">■ </span>食品加工</p>
				</li>
				<li>
					<p class="gyl_title"><span class="color_4">■ </span>其他产业</p>
				</li>
			</ul>
				
		</div>

		<div class="pan">				
			<ul>
				<li>			
					<div class="pan_chart" ref="chart1">
					</div>
				</li>
				<li>					
					<div class="pan_chart" ref="chart2">
					</div>
				</li>
				<li>					
					<div class="pan_chart" ref="chart3">
					</div>
				</li>
			</ul>
		</div>


	</div>
  
</template>




<script>
import * as Echarts from 'echarts';
import { getCyts } from '../../network/cyts';

export default {
    data() 
	{ 
		return { 
			timer: null,		// 定时器
			cyData: [],			// 产业数据

			chart1: null,
			option1: null,

			chart2: null,
			option2: null,

			chart3: null,
			option3: null,
		} 	
	}, 

	mounted() {
		this.init();
		this.timer = setInterval(() => {
       		setTimeout(this.refresh(), 0)	   
   		}, 1000*3600)
	},
	beforeDestroy(){
		clearInterval(this.timer);        
		this.timer = null;
	},

	methods: {

		init() {
			// 1.初始化
			this.chart1 = Echarts.init(this.$refs.chart1);
			this.chart2 = Echarts.init(this.$refs.chart2);	
			this.chart3 = Echarts.init(this.$refs.chart3);	
			
			// 2.配置数据
			var P = ["#9BF5ED", "#FF5D3B", "#65FF94", "#EB9200"];			
			var C = function(e) {
				return e.toString().replace(/(?=(\B)(\d{3})+$)/g, ",");
			}

			this.option1 = {
							
				grid: {
					left: "0%",
					right: "0%",
					bottom: "0%",
					top: "0%",
					containLabel: !0
				},	
				color: P,
				tooltip: {
					trigger: "item"
				},
				title: [{
					text: "{name|产值}\n{val|(亿元)}",
					top: "center",
					left: "center",
					textStyle: {
						rich: {
							name: {
								fontSize: 13,
								fontWeight: "normal",
								color: "#c8d228",
								padding: [3, 0]
							},
							val: {
								fontSize: 12,
								color: "#c8d228"
							}
						}
					}
				}],
				series: [{
					type: "pie",
					radius: ["50%", "70%"],
					center: ["50%", "50%"],
					data: [{
						name: "电子信息",
						value: "182.5",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#93FFE5'},
									{offset: 1, color: '#00CCF7'}
								],
							}
						},
					},
					{
						name: "装备制造",
						value: "149.3",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#FF7E3B'},
									{offset: 1, color: '#FF5D3B'}
								],
							}
						},
					},
					{
						name: "食品加工",
						value: "186.3",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#3BDC7D'},
									{offset: 1, color: '#1BD643'}
								],
							}
						},
					},
					{
						name: "其它",
						value: "312.1",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#F7C400'},
									{offset: 1, color: '#EB9200'}
								],
							}
						},
					}],
					hoverAnimation: !1,
					itemStyle: {
						normal: {
							borderWidth: 2
						}
					},
					labelLine: {
						normal: {
							length: 10,
							length2: 5,
							lineStyle: {
								color: "#fff"
							}
						}
					},
					label: {
						normal: {
							formatter: function(e) {
								return "{value|" + C(e.value) + "}"
							},
							rich: {
								name: {
									fontSize: 14,
									color: "#fff"
								},
								// value: {
								// 	fontSize: 11,
								// 	color: "#fff"
								// }
							}
						}
					}
				}]
			};
								
    
			var T = [{
				name: "电子信息",
				value: "1.26",
				itemStyle: {
					color: {
						colorStops: [
							{offset: 0, color: '#93FFE5'},
							{offset: 1, color: '#00CCF7'}
						],
					}
				},
			},
			{
				name: "装备制造",
				value: "0.81",
				itemStyle: {
					color: {
						colorStops: [
							{offset: 0, color: '#FF7E3B'},
							{offset: 1, color: '#FF5D3B'}
						],
					}
				},
			},
			{
				name: "食品加工",
				value: "11.93",
				itemStyle: {
					color: {
						colorStops: [
							{offset: 0, color: '#3BDC7D'},
							{offset: 1, color: '#1BD643'}
						],
					}
				},
			},
			{
				name: "其它",
				value: "4.16",
				itemStyle: {
					color: {
						colorStops: [
							{offset: 0, color: '#F7C400'},
							{offset: 1, color: '#EB9200'}
						],
					}
				},
			}];
			
			this.option2 = {
								
				color: P,
				title: [{
					text: "{name|税收}\n{val|(亿元)}",
					top: "center",
					left: "center",
					textStyle: {
						rich: {
							name: {
								fontSize: 13,
								fontWeight: "normal",
								color: "#c8d228",
								padding: [3, 0]
							},
							val: {
								fontSize: 12,
								color: "#c8d228"
							}
						}
					}
				}],
				tooltip: {
					trigger: "item"
				},
				series: [{
					type: "pie",
					radius: ["50%", "70%"],
					center: ["50%", "50%"],
					data: T,
					hoverAnimation: !1,
					itemStyle: {
						normal: {
							borderWidth: 2
						}
					},
					labelLine: {
						normal: {
							length: 10,
							length2: 5,
							lineStyle: {
								color: "#fff"
							}
						}
					},
					label: {
						normal: {
							formatter: function(e) {
								return "{value|" + C(e.value) + "}"
							},
							rich: {
								name: {
									fontSize: 14,
									color: "#fff"
								},
								// value: {
								// 	fontSize: 11,
								// 	color: "#fff"
								// }
							}
						}
					}
				}]
			};


			var T1 = [{
				name: "电子信息",
				value: "162",
				itemStyle: {
					color: {
						colorStops: [
							{offset: 0, color: '#93FFE5'},
							{offset: 1, color: '#00CCF7'}
						],
					}
				},
			},
			{
				name: "装备制造",
				value: "142",
				itemStyle: {
					color: {
						colorStops: [
							{offset: 0, color: '#FF7E3B'},
							{offset: 1, color: '#FF5D3B'}
						],
					}
				},
			},
			{
				name: "食品加工",
				value: "36",
				itemStyle: {
					color: {
						colorStops: [
							{offset: 0, color: '#3BDC7D'},
							{offset: 1, color: '#1BD643'}
						],
					}
				},
			},
			{
				name: "其它",
				value: "87",
				itemStyle: {
					color: {
						colorStops: [
							{offset: 0, color: '#F7C400'},
							{offset: 1, color: '#EB9200'}
						],
					}
				},
			}];

			this.option3 = {
				color: P,
				title: [{
					text: "{name|数量}\n{val|(家)}",
					top: "center",
					left: "center",
					textStyle: {
						rich: {
							name: {
								fontSize: 13,
								fontWeight: "normal",
								color: "#c8d228",
								padding: [3, 0]
							},
							val: {
								fontSize: 12,
								color: "#c8d228"
							}
						}
					}
				}],
				tooltip: {
					trigger: "item"
				},
				series: [{
					type: "pie",
					radius: ["50%", "70%"],
					center: ["50%", "50%"],
					data: T1,
					hoverAnimation: !1,
					itemStyle: {
						normal: {
							borderWidth: 2
						}
					},
					labelLine: {
						normal: {
							length: 10,
							length2: 5,
							lineStyle: {
								color: "#fff"
							}
						}
					},
					label: {
						normal: {
							formatter: function(e) {
								return "{value|" + C(e.value) + "}"
							},
							rich: {
								name: {
									fontSize: 14,
									color: "#fff"
								},
								// value: {
								// 	fontSize: 11,
								// 	color: "#fff"
								// }
							}
						}
					}
				}]	
			};


			// 3.传入数据
			this.chart1.setOption(this.option1);
			this.chart2.setOption(this.option2);
			this.chart3.setOption(this.option3);


			this.refresh();
		},

		refresh() {		
			
			getCyts().then(res=>{
				this.yqData = res.data;	
								
				for (var i=0; i<4; i++) {
					this.option1.series[0].data[i].value = res.data[i].turnover;
					this.option2.series[0].data[i].value = res.data[i].tax;
					this.option3.series[0].data[i].value = res.data[i].quantity;
				}
					
				this.chart1.clear();
				this.chart1.setOption(this.option1);

				this.chart2.clear();
				this.chart2.setOption(this.option2);
				
				this.chart3.clear();
				this.chart3.setOption(this.option3);
			})


		},

		clickAll() {
			// console.log("---------------All");
			this.$parent.selectCY(0);
		}

	}
}
</script>


<style scoped>

.module {	
	cursor: pointer;
}

.legend {	
	top: -10px;
	left: 200px;
	width: 370px;
	height: 30px;
  	position:relative;
	  
	/* background-color: brown;	 */
}

.legend li {
	float: left;	
	width: 90px;
  	font-size: 14px;
	color: rgb(255, 255, 255, 0.6);
}

.color_1{	
	color: #9BF5ED;
}
.color_2{	
	color: #FF5D3B;
}
.color_3{	
	color: #65FF94;
}
.color_4{	
	color: #EB9200;
}


.pan {	
	top: -15px;
	left: 10px;
	width: 780px;
	height: 168px;
  	position:relative;
	/* background-color: brown; */
	pointer-events: none;
}

.pan ul {
	margin: 0;
	left: 0px;

}
.pan li {
	float: left;
	width: 260px;
	height: 168px;
	/* background-color: red; */
}

.pan_chart {	
	width: 100%;
	height: 100%;
}



</style>
<template>
			
	<ul>
		<li>			
        	<img src="~@/assets/images/cyts/btnQYXC.png" alt="" @click="openvideo">
			<div ref="btn1" class="capt btn1" @click="openvideo">
				<p>企业宣传</p>
			</div>
		</li>
		<li>			
        	<img src="~@/assets/images/cyts/btnSJVR.png" alt="" @click="openvrimg">
			<div ref="btn3" class="capt btn3" @click="openvrimg">
				<p>实景VR</p>
			</div>
		</li>
		<!-- <li>		
        	<img src="~@/assets/images/cyts/btnVRSP.png" alt="" @click="openvrvideo">
			<div ref="btn4" class="capt btn4" @click="openvrvideo">
				<p>VR视频</p>
			</div>
		</li> -->
	</ul>
  
</template>



<script>
import { getMedia } from '../../network/media';

export default {
 
    data() 
	{ 
		return { 
			qyxx: {},
		} 	
	}, 
	
    mounted() 
	{ 
		this.init();
	},

	methods: {
		
		init() {

			// 产业特色（11）的多媒体资源设置
			getMedia("11").then(res=>{
				this.mediaSetting = res.data[0];	
				// console.log(this.mediaSetting.prefix);
				
			})

		},

		// 选择企业信息
		selectQyxx(qyxx) {
			this.qyxx = qyxx;
			// console.log("企业信息：" + this.qyxx.id + "; " + this.qyxx.qyname);
						
					
			// 设置状态
			if (this.qyxx.video.length > 0) {
				this.$refs.btn1.style.opacity = 1;
			}
			else {
				this.$refs.btn1.style.opacity = 0.5;
			}
						
			if (this.qyxx.vrpano.length > 0) {
				this.$refs.btn3.style.opacity = 1;
			}
			else {
				this.$refs.btn3.style.opacity = 0.5;
			}
			
			if (this.qyxx.vrvideo.length > 0) {
				this.$refs.btn4.style.opacity = 1;
			}
			else {
				this.$refs.btn4.style.opacity = 0.5;
			}		
		},


		// 打开对话框
		openvideo() {
			if (this.qyxx.video.length <= 0) {
				return;
			}

			var vadioURL= this.mediaSetting.prefix + this.qyxx.qyname + "/" + this.mediaSetting.video + "/" + this.qyxx.video
			console.log(vadioURL)

			this.$parent.openVideo(this.qyxx.id, vadioURL);
		},
		openvrimg() {
			if (this.qyxx.vrpano.length <= 0) {
				return;
			}

			var vrURL= this.mediaSetting.prefix + this.qyxx.qyname + "/" + this.mediaSetting.vrimg + "/" + this.qyxx.vrpano
			console.log(vrURL)

			this.$parent.openVRImg(this.qyxx.id, vrURL);
		},
		openvrvideo() {
			if (this.qyxx.vrvideo.length <= 0) {
				return;
			}

			var vrvadioURL= this.mediaSetting.prefix + this.qyxx.qyname + "/" + this.mediaSetting.vrvideo + "/" + this.qyxx.vrvideo
			console.log(vrvadioURL)

			this.$parent.openVRVideo(this.qyxx.id, vrvadioURL, "false");
		},

	}

 
}
</script>



<style scoped>


ul {
	margin: 0 0 0 25px;
	left: 0px;

}
li {
	float: left;
	width: 250px;
	height: 90px;
	position: relative;
	cursor: pointer;
	
	/* background-color: red; */
}


.capt {	
	top: 28px;
	width: 260px;
	height: 34px;
	text-align: center;
	position: absolute;			
}

.capt p {	
	left: 32px;
	color: #F1F1F1;
  	font-size: 22px;
  	line-height: 34px;
  	text-align: left;
  	position:relative;	
	color: rgb(255, 255, 255, 1);
}

.btn1 {	
	left: 65px;
	background: url("~@/assets/images/main/icoBF.png") no-repeat left; 
}

.btn2 {	
	left: 85px;
	background: url("~@/assets/images/main/icoTC.png") no-repeat left; 
}

.btn3 {	
	left: 75px;
	background: url("~@/assets/images/main/icoVR.png") no-repeat left; 
}

.btn4 {	
	left: 75px;
	background: url("~@/assets/images/main/icoSP.png") no-repeat left; 
}


</style>
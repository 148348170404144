<template>

	<div class="module">
		<div class="caption">
			<p>分类产业数据</p>
		</div>
		
		<div class="pan">				
			<ul>
				<li @click="clickCY(1)">			
					<p class="cy_title">电子信息产业（2018-2020）</p>

					<div class="pan_chart1" ref="chart1">
					</div>

					<div class="pan_chart2" ref="chart2">
					</div>
				</li>
				<li @click="clickCY(2)">					
					<p class="cy_title">装备制造产业（2018-2020）</p>
					
					<div class="pan_chart1" ref="chart3">
					</div>

					<div class="pan_chart2" ref="chart4">
					</div>
				</li>
				<li @click="clickCY(3)">					
					<!-- <p class="cy_title">食品加工产业（2018-2020）</p> -->

					<div class="pan_chart1" ref="chart5" v-show="false">
					</div>

					<div class="pan_chart2" ref="chart6" v-show="false">
					</div>
				</li>
			</ul>
		</div>


	</div>
  
</template>



<script>
import * as Echarts from 'echarts';
import { getCyts, getCyyear } from '../../network/cyts';

export default {
    data() 
	{ 
		return { 
			timer: null,		// 定时器

			chart1: null,
			option1: null,

			chart2: null,
			option2: null,
			
			chart3: null,
			option3: null,

			chart4: null,
			option4: null,
			
			chart5: null,
			option5: null,

			chart6: null,
			option6: null,
		} 	
	}, 
    mounted() 
	{ 
		this.init();
		this.timer = setInterval(() => {
       		setTimeout(this.refresh(), 0)	   
   		}, 1000*10)
	},
	beforeDestroy(){
		clearInterval(this.timer);        
		this.timer = null;
	},

	methods: {

		init() {
			//1.初始化
			this.chart1 = Echarts.init(this.$refs.chart1);
			this.chart2 = Echarts.init(this.$refs.chart2);	
			this.chart3 = Echarts.init(this.$refs.chart3);	
			this.chart4 = Echarts.init(this.$refs.chart4);	
			this.chart5 = Echarts.init(this.$refs.chart5);	
			this.chart6 = Echarts.init(this.$refs.chart6);	
			

			//2.配置数据
			this.option1 = {
							
				grid: {
					left: "3%",
					right: "5%",
					bottom: "7%",
					top: "22%",
					containLabel: !0
				},							
				tooltip: {
					trigger: "axis",
					axisPointer: {
						type: "shadow"
					}
				},
				xAxis: {
					type: "category",
					data: ["2017", "2018", "2019"],
					axisLine: {
						lineStyle: {
							color: 'rgba(255, 255, 255, 0.8)',					
						}
					},
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12
					},
				},
				yAxis: {
					name: "企业（家）",
					nameTextStyle: {
						padding: [0, 0, 0, 30],
						color: "#EB9200",
						fontSize: 15
					},
					type: "value",
					max: 150,
					interval: 50,
					axisLine: {
						show: true,
						lineStyle: {					
							width: 1,		
							color: 'rgba(255, 255, 255, 0.3)',
						}
					},
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12,
						formatter: '{value}'
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							width: 1,
							color: 'rgba(255, 255, 255, 0.1)',
						}
					},
				},
				series: [{
					data: [126, 129, 135],
					type: "bar",
					name: "企业数目",
					barWidth: "30%",
					itemStyle: {
						normal: {
							barBorderRadius:[5, 5, 0, 0],
							label: {
								show: !0,
								position: "top",
								textStyle: {
									color: "#cdc7c4"
								}
							},
							color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [{
								offset: 1,
								color: "#93FFE5"
							},
							{
								offset: 0,
								color: "#00CCF7"
							}])
						}
					}
				}]
			};					
						
			this.option2 = {
							
				legend: {
					top: "10%",
					right: "3%",
					textStyle: {
						color: "#F1F1F1"
					},
					icon: "circle",
					data: ["产值", "税收"]
				},	
				tooltip: {
					trigger: "axis",
					axisPointer: {
						type: "shadow"
					}
				},
				grid: [{
					left: "5%",
					right: "40%",
					bottom: "0%",
					top: "30%",
				},
				{
					left: "60%",
					right: "3%",
					bottom: "0%",
					top: "30%",
				}],
				xAxis: [{
					type: "value",
					gridIndex: 0,
					inverse: !0,
					min: 0,
					max: 230,
					splitNumber: 2,
					axisLabel: {
						show: !1
					},
					axisTick: {
						show: !1
					},
					splitLine: {
						show: !1
					}
				},
				{
					type: "value",
					gridIndex: 1,
					min: 0,
					max: 15,
					splitNumber: 2,
					axisLabel: {
						show: !1
					},
					axisTick: {
						show: !1
					},
					splitLine: {
						show: !1
					}
				}],
				yAxis: [{
					name: "单位：亿",
					nameTextStyle: {
						padding: [0, 0, -2, -220],
						color: "#EB9200",
						fontSize: 15
					},
					type: "category",
					gridIndex: 0,
					splitLine: {
						show: !1
					},
					axisLabel: {
						show: !1
					},
					axisTick: {
						show: !1
					},
					data: ["2019", "2018", "2017"]
				},
				{
					type: "category",
					gridIndex: 1,
					axisLabel: {
						show: !1
					},
					splitLine: {
						show: !1
					},
					axisTick: {
						show: !1
					},
					data: ["2019", "2018", "2017"],
					position: "right"
				}],
				series: [{
					name: "产值",
					type: "bar",
					seriesLayoutBy: "row",
					label: {
						show: !0,
						position: "left"
					},
					barWidth:'20',				//---柱形宽度
					data: [182.3, 165.8, 163.3],
					itemStyle: {
						normal: {
							barBorderRadius:[10, 0, 0, 10],
							label: {
								show: !0,
								position: "left",
								textStyle: {
									color: "#cdc7c4"
								}
							},
							color: new Echarts.graphic.LinearGradient(1, 0, 0, 0, [{
								offset: 0,
								color: "#93FFE5"
							},
							{
								offset: 1,
								color: "#00CCF7"
							}])
						}
					}
				},
				{
					name: "税收",
					type: "bar",
					xAxisIndex: 1,
					yAxisIndex: 1,
					seriesLayoutBy: "row",
					label: {
						show: !0,
						position: "right"
					},
					barWidth:'20',				//---柱形宽度
					data: [2.15, 1.99, 1.91],
					itemStyle: {
						normal: {
							barBorderRadius:[0, 10, 10, 0],
							color: new Echarts.graphic.LinearGradient(1, 0, 0, 0, [{
								offset: 0,
								color: "#FF5D3B"
							},
							{
								offset: 1,
								color: "#FF7E3B"
							}])
						}
					}
				}]	
			};

			this.option3 = {
							
				grid: {
					left: "3%",
					right: "5%",
					bottom: "7%",
					top: "22%",
					containLabel: !0
				},							
				tooltip: {
					trigger: "axis",
					axisPointer: {
						type: "shadow"
					}
				},
				xAxis: {
					type: "category",
					data: ["2017", "2018", "2019"],
					axisLine: {
						lineStyle: {
							color: 'rgba(255, 255, 255, 0.8)',					
						}
					},
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12
					},
				},
				yAxis: {
					name: "企业（家）",
					nameTextStyle: {
						padding: [0, 0, 0, 30],
						color: "#EB9200",
						fontSize: 15
					},
					type: "value",
					max: 150,
					interval: 50,
					axisLine: {
						show: true,
						lineStyle: {					
							width: 1,		
							color: 'rgba(255, 255, 255, 0.3)',
						}
					},
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12,
						formatter: '{value}'
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							width: 1,
							color: 'rgba(255, 255, 255, 0.1)',
						}
					},
				},
				series: [{
					data: [126, 129, 135],
					type: "bar",
					name: "企业数目",
					barWidth: "30%",
					itemStyle: {
						normal: {
							barBorderRadius:[5, 5, 0, 0],
							label: {
								show: !0,
								position: "top",
								textStyle: {
									color: "#cdc7c4"
								}
							},
							color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [{
								offset: 1,
								color: "#F7C400"
							},
							{
								offset: 0,
								color: "#EB9200"
							}])
						}
					}
				}]
			};					
						
			this.option4 = {
							
				legend: {
					top: "10%",
					right: "3%",
					textStyle: {
						color: "#F1F1F1"
					},
					icon: "circle",
					data: ["产值", "税收"]
				},		
				tooltip: {
					trigger: "axis",
					axisPointer: {
						type: "shadow"
					}
				},
				grid: [{
					left: "5%",
					right: "40%",
					bottom: "0%",
					top: "30%",
				},
				{
					left: "60%",
					right: "3%",
					bottom: "0%",
					top: "30%",
				}],
				xAxis: [{
					type: "value",
					gridIndex: 0,
					inverse: !0,
					min: 0,
					max: 230,
					splitNumber: 2,
					axisLabel: {
						show: !1
					},
					axisTick: {
						show: !1
					},
					splitLine: {
						show: !1
					}
				},
				{
					type: "value",
					gridIndex: 1,
					min: 0,
					max: 15,
					splitNumber: 2,
					axisLabel: {
						show: !1
					},
					axisTick: {
						show: !1
					},
					splitLine: {
						show: !1
					}
				}],
				yAxis: [{
					name: "单位：亿",
					nameTextStyle: {
						padding: [0, 0, -2, -220],
						color: "#EB9200",
						fontSize: 15
					},
					type: "category",
					gridIndex: 0,
					splitLine: {
						show: !1
					},
					axisLabel: {
						show: !1
					},
					axisTick: {
						show: !1
					},
					data: ["2019", "2018", "2017"]
				},
				{
					type: "category",
					gridIndex: 1,
					axisLabel: {
						show: !1
					},
					splitLine: {
						show: !1
					},
					axisTick: {
						show: !1
					},
					data: ["2019", "2018", "2017"],
					position: "right"
				}],
				series: [{
					name: "产值",
					type: "bar",
					seriesLayoutBy: "row",
					label: {
						show: !0,
						position: "left"
					},
					barWidth:'20',				//---柱形宽度
					data: [182.3, 165.8, 163.3],
					itemStyle: {
						normal: {
							barBorderRadius:[10, 0, 0, 10],
							label: {
								show: !0,
								position: "left",
								textStyle: {
									color: "#cdc7c4"
								}
							},
							color: new Echarts.graphic.LinearGradient(1, 0, 0, 0, [{
								offset: 0,
								color: "#F7C400"
							},
							{
								offset: 1,
								color: "#EB9200"
							}])
						}
					}
				},
				{
					name: "税收",
					type: "bar",
					xAxisIndex: 1,
					yAxisIndex: 1,
					seriesLayoutBy: "row",
					label: {
						show: !0,
						position: "right"
					},
					barWidth:'20',				//---柱形宽度
					data: [2.15, 1.99, 1.91],
					itemStyle: {
						normal: {
							barBorderRadius:[0, 10, 10, 0],
							color: new Echarts.graphic.LinearGradient(1, 0, 0, 0, [{
								offset: 0,
								color: "#FF5D3B"
							},
							{
								offset: 1,
								color: "#FF7E3B"
							}])
						}
					}
				}]	
			};
			
			this.option5 = {
							
				grid: {
					left: "3%",
					right: "5%",
					bottom: "7%",
					top: "22%",
					containLabel: !0
				},							
				tooltip: {
					trigger: "axis",
					axisPointer: {
						type: "shadow"
					}
				},
				xAxis: {
					type: "category",
					data: ["2017", "2018", "2019"],
					axisLine: {
						lineStyle: {
							color: 'rgba(255, 255, 255, 0.8)',					
						}
					},
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12
					},
				},
				yAxis: {
					name: "企业（家）",
					nameTextStyle: {
						padding: [0, 0, 0, 30],
						color: "#EB9200",
						fontSize: 15
					},
					type: "value",
					max: 150,
					interval: 50,
					axisLine: {
						show: true,
						lineStyle: {					
							width: 1,		
							color: 'rgba(255, 255, 255, 0.3)',
						}
					},
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12,
						formatter: '{value}'
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							width: 1,
							color: 'rgba(255, 255, 255, 0.1)',
						}
					},
				},
				series: [{
					data: [126, 129, 135],
					type: "bar",
					name: "企业数目",
					barWidth: "30%",
					itemStyle: {
						normal: {
							barBorderRadius:[5, 5, 0, 0],
							label: {
								show: !0,
								position: "top",
								textStyle: {
									color: "#cdc7c4"
								}
							},
							color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [{
								offset: 1,
								color: "#3BDC7D"
							},
							{
								offset: 0,
								color: "#1BD643"
							}])
						}
					}
				}]
			};					
						
			this.option6 = {
							
				legend: {
					top: "10%",
					right: "3%",
					textStyle: {
						color: "#F1F1F1"
					},
					icon: "circle",
					data: ["产值", "税收"]
				},		
				tooltip: {
					trigger: "axis",
					axisPointer: {
						type: "shadow"
					}
				},
				grid: [{
					left: "5%",
					right: "40%",
					bottom: "0%",
					top: "30%",
				},
				{
					left: "60%",
					right: "10%",
					bottom: "0%",
					top: "30%",
				}],
				xAxis: [{
					type: "value",
					gridIndex: 0,
					inverse: !0,
					min: 0,
					max: 230,
					splitNumber: 2,
					axisLabel: {
						show: !1
					},
					axisTick: {
						show: !1
					},
					splitLine: {
						show: !1
					}
				},
				{
					type: "value",
					gridIndex: 1,
					min: 0,
					max: 15,
					splitNumber: 2,
					axisLabel: {
						show: !1
					},
					axisTick: {
						show: !1
					},
					splitLine: {
						show: !1
					}
				}],
				yAxis: [{
					name: "单位：亿",
					nameTextStyle: {
						padding: [0, 0, -2, -220],
						color: "#EB9200",
						fontSize: 15
					},
					type: "category",
					gridIndex: 0,
					splitLine: {
						show: !1
					},
					axisLabel: {
						show: !1
					},
					axisTick: {
						show: !1
					},
					data: ["2019", "2018", "2017"]
				},
				{
					type: "category",
					gridIndex: 1,
					axisLabel: {
						show: !1
					},
					splitLine: {
						show: !1
					},
					axisTick: {
						show: !1
					},
					data: ["2019", "2018", "2017"],
					position: "right"
				}],
				series: [{
					name: "产值",
					type: "bar",
					seriesLayoutBy: "row",
					label: {
						show: !0,
						position: "left"
					},
					barWidth:'20',				//---柱形宽度
					data: [182.3, 165.8, 163.3],
					itemStyle: {
						normal: {
							barBorderRadius:[10, 0, 0, 10],
							label: {
								show: !0,
								position: "left",
								textStyle: {
									color: "#cdc7c4"
								}
							},
							color: new Echarts.graphic.LinearGradient(1, 0, 0, 0, [{
								offset: 0,
								color: "#3BDC7D"
							},
							{
								offset: 1,
								color: "#1BD643"
							}])
						}
					}
				},
				{
					name: "税收",
					type: "bar",
					xAxisIndex: 1,
					yAxisIndex: 1,
					seriesLayoutBy: "row",
					label: {
						show: !0,
						position: "right"
					},
					barWidth:'20',				//---柱形宽度
					data: [2.15, 1.99, 1.91],
					itemStyle: {
						normal: {
							barBorderRadius:[0, 10, 10, 0],
							color: new Echarts.graphic.LinearGradient(1, 0, 0, 0, [{
								offset: 0,
								color: "#FF5D3B"
							},
							{
								offset: 1,
								color: "#FF7E3B"
							}])
						}
					}
				}]	
			};

			// 3.传入数据
			this.chart1.setOption(this.option1);
			this.chart2.setOption(this.option2);
			this.chart3.setOption(this.option3);
			this.chart4.setOption(this.option4);
			this.chart5.setOption(this.option5);
			this.chart6.setOption(this.option6);


			this.refresh();
		},

		refresh() {		
			
			getCyyear('').then(res=>{
								
				this.yqname = res.data[0].yqname;

				this.option1.xAxis.data = [];
				this.option1.series[0].data = [];
				this.option2.yAxis[0].data = [];
				this.option2.yAxis[1].data = [];
				this.option2.series[0].data = [];
				this.option2.series[1].data = [];
				
				this.option3.xAxis.data = [];
				this.option3.series[0].data = [];
				this.option4.yAxis[0].data = [];
				this.option4.yAxis[1].data = [];
				this.option4.series[0].data = [];
				this.option4.series[1].data = [];
				
				this.option5.xAxis.data = [];
				this.option5.series[0].data = [];
				this.option6.yAxis[0].data = [];
				this.option6.yAxis[1].data = [];
				this.option6.series[0].data = [];
				this.option6.series[1].data = [];

				for (var i=0; i<res.data.length; i++) {
					if (res.data[i].cyid == 1) {
						this.option1.xAxis.data.push(res.data[i].year);
						this.option1.series[0].data.push(res.data[i].quantity);

						this.option2.yAxis[0].data.push(res.data[i].year);
						this.option2.yAxis[1].data.push(res.data[i].year);
						this.option2.series[0].data.push(res.data[i].turnover);
						this.option2.series[1].data.push(res.data[i].tax);

					} else if (res.data[i].cyid == 2) {
						this.option3.xAxis.data.push(res.data[i].year);
						this.option3.series[0].data.push(res.data[i].quantity);

						this.option4.yAxis[0].data.push(res.data[i].year);
						this.option4.yAxis[1].data.push(res.data[i].year);
						this.option4.series[0].data.push(res.data[i].turnover);
						this.option4.series[1].data.push(res.data[i].tax);

					} else if (res.data[i].cyid == 3) {
						this.option5.xAxis.data.push(res.data[i].year);
						this.option5.series[0].data.push(res.data[i].quantity);

						this.option6.yAxis[0].data.push(res.data[i].year);
						this.option6.yAxis[1].data.push(res.data[i].year);
						this.option6.series[0].data.push(res.data[i].turnover);
						this.option6.series[1].data.push(res.data[i].tax);

					} 

				}

				this.chart1.clear();
				this.chart1.setOption(this.option1);

				this.chart2.clear();
				this.chart2.setOption(this.option2);
				
				this.chart3.clear();
				this.chart3.setOption(this.option3);
				
				this.chart4.clear();
				this.chart4.setOption(this.option4);
				
				this.chart5.clear();
				this.chart5.setOption(this.option5);
				
				this.chart6.clear();
				this.chart6.setOption(this.option6);

			})
		},

		
		clickCY(cyid) {
			// console.log("---------------cyid = " + cyid); 
			this.$parent.selectCY(cyid);
		}


	}
}

</script>



<style scoped>

.pan {	
	top: 15px;
	left: 10px;
	width: 790px;
	height: 370px;
  	position:relative;
	/* background-color: brown; */
}

.pan ul {
	margin: 0;
	left: 0px;

}
.pan li {
	float: left;
	width: 262px;
	height: 370px;
	cursor: pointer;
}

.pan_chart1 {	
	margin: 10px 0 0 0 ;
	width: 253px;
	height: 167px;
	border-radius:10px;
	background-color: #152a3a;	
	pointer-events: none;
}

.pan_chart2 {	
	margin: 3px 0 0 0 ;
	width: 253px;
	height: 167px;
	border-radius:10px;
	background-color: #152a3a;	
	pointer-events: none;
}

.cy_title {		
  	font-size: 16px;
  	line-height: 24px;
	color: #9BF5ED;  	  	
}


</style>
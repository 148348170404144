<template>
		
    <div id="cesiumContainer">        
    </div>

</template>


<script>
import { MapInit, MapViewInit, MapAddDom_Tianditu, MapPolyline, MapViewerRemoveAll, MapAddDem, MapDebugCamera, MapFlyToDuration, MapAdd3DTiles, MapAddTiltPhoto } from './mapbase';
import { getCyts, getQyxx, getCywalk } from '../../network/cyts';
import { getMedia, getCode } from '../../network/media';

export default {
    data () {
        return {
            viewer :'',
            tileset: '',
            
			mediaSetting: {},       // 保存企业导览[14]路线配置
			gisDemSetting: {},


            // 企业信息
            qyxxData: [],
            currentid: 0,  // 当前id（0=未选中）
            
            // 图标大小
            big_width: 54,
            big_height: 59,
            little_width: 36,
            little_height: 39,   

            
            
			yqData: [
			],					// 园区数据

            arrYQWalk: [],      // 园区漫游数组

            // 漫游
            walk_yqid: 0,
            walk_index: 0,

            // 语音播放器
            audio: {},

        }
    },
    
    mounted() {
        
        XE.ready().then(this.init); 

    },
    
    destroyed() {
        this.viewer.destroy();
        this.viewer = null;
    },

    methods: {
        
        init() {
                
            this.initCY();

            this.audio = new Audio();

            // 
            this.earth = new XE.Earth('cesiumContainer');
            this.viewer = this.earth.czm.viewer;
            window.viewer = this.viewer;
            MapViewInit(this.viewer);

            // 影像和高程
            MapAddDom_Tianditu(this.viewer);
            this.initGisDem();



            let that = this;


            // cesium 左键点击事件
            var handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
            handler.setInputAction(function (movement) {
                var pick = viewer.scene.pick(movement.position);

                that.debugCamera();


                // if (Cesium.defined(pick) && (pick.id.id === 'id')) {
                if (Cesium.defined(pick) ) {             
                    that.$parent.selectQyxx(that.qyxxData[pick.id.typeid-1][pick.id.qyxxindex]);

                    // 选中放大
                    that.SelectPin(pick.id.typeid, pick.id.id);

                }

            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
            

            MapFlyToDuration(this.viewer, 1, 119.35072363592676, 26.083644838944874, 3601.6125288002518, 0, -90, 0);


			getCyts().then(res=>{
				that.yqData = res.data;	
								
                for (var i=0; i<that.yqData.length; i++) {
                    let yqid = that.yqData[i].id;
			        getCywalk(yqid).then(res1=>{                       
                        that.arrYQWalk[yqid] = res1.data;                
                    })
                }
			})

            
			// VR视频播放器地址（14）的多媒体资源设置
			getMedia("14").then(res=>{
				this.mediaSetting = res.data[0];	
				console.log(this.mediaSetting.prefix);				
			})
            
        },

        initGisDem() {
            
			// Gis配置（16）
			getCode("gis_set", "16").then(res=>{
				this.gisDemSetting = res.data[0];					
                
                console.log(this.gisDemSetting.value1);
                // MapAddDem(this.viewer, "http://39.107.47.88:8015/fjsmart3d/3dtiles/dem/fujian/quansheng");
                MapAddDem(this.viewer, this.gisDemSetting.value1);
			})

        },

        // 
        initCY() {
            this.qyxxData = [];            
            this.currentid = 0;
            
            for (var i=0; i<4; i++) {

                this.qyxxData.push({});
                getQyxx(i+1).then(res=>{

                    if (res.data.length > 0) {
                        var cyid = parseInt(res.data[0].cyid);

                        this.qyxxData[cyid-1] = res.data;	
                        this.showPOI(cyid, false, false);
                    }
                })

            }

        },

        // 选择产业特色
        selectCY(cyid) {            

            this.currentid = 0;            
            var cameraindex = 3;    // 默认位置为配置在其他产业

            if (cyid == 0) {
                this.showPOI(0, true, false);
            }
            else {
                this.showPOI(cyid, true, true);
                cameraindex = cyid-1;
            }

            // 摄像头参数转数组            
            console.log("cy-camera[%d]: %s", cameraindex, this.yqData[cameraindex].camera);
            var strarrcamera = this.yqData[cameraindex].camera.split(",");
            var arrcamera = strarrcamera.map((value)=>{
                return parseFloat(value);
            })
            if (arrcamera.length == 6) {
                MapFlyToDuration(this.viewer, 1, arrcamera[0], arrcamera[1], arrcamera[2], arrcamera[3], arrcamera[4], arrcamera[5]);                
            }
            else {
                MapFlyToDuration(this.viewer, 1, 119.15466077008783, 25.474044984387948, 30742.30795784303, 0, -90, 0);
            }


            
            this.walk_yqid = cyid;
            this.walk_index = 0;

        },

		// 选择企业信息
		selectQyxx(qyxx) {
            this.SelectPin(qyxx.cyid, qyxx.id);						
		},


        // 显示兴趣点
        showPOI(cyid, isclear, showname) {
            // console.log("showPOI cyid = " +cyid);

            if (isclear) {
                this.viewerremoveAll();
            }
            
            
            var fromindex = 0;
            var toindex = 3;
            if (cyid > 0) {
                fromindex = cyid-1;
                toindex = cyid-1;
            }

            console.log("currentid = " + this.currentid);
            var tmptypeid = 0;
            var tmpqyxxid = this.currentid;
            var tmpqyxxindex = 0;
            
            for (var index=fromindex; index<=toindex; index++) {

                for (var i=0; i<this.qyxxData[index].length; i++) {
                      
                    var name = ""
                    if (showname) {
                        name = this.qyxxData[index][i].simplename;
                    }

                    this.AddPin(index+1, this.qyxxData[index][i].id, i, name, this.qyxxData[index][i].longitude,this.qyxxData[index][i].latitude); 
                    
                    if (tmpqyxxid == 0) {
                        tmptypeid = index+1;
                        tmpqyxxid = this.qyxxData[index][i].id
                        tmpqyxxindex = i;
                    }
                }
                
            }

            
            // 默认选中第一个
            if (this.currentid==0 && tmpqyxxid>0) {
				this.$parent.selectQyxx(this.qyxxData[tmptypeid-1][tmpqyxxindex]);
                // console.log("tmptypeid = " + tmptypeid);
                // console.log("tmpqyxxid = " + tmpqyxxid);
                this.SelectPin(tmptypeid, tmpqyxxid);
            }

        },


        // 清空viewer所有内容
        viewerremoveAll() {
            this.viewer.dataSources.removeAll();
            this.viewer.entities.removeAll();
            this.viewer.scene.postProcessStages.removeAll();

            this.debugCamera()
        },

        debugCamera() {
            let position = this.viewer.scene.camera.position    
            let cartographic = Cesium.Cartographic.fromCartesian(position);
            let x = Cesium.Math.toDegrees(cartographic.longitude);
            let y = Cesium.Math.toDegrees(cartographic.latitude);
            let z = cartographic.height;

            let heading = Cesium.Math.toDegrees(this.viewer.scene.camera.heading);
            let pitch = Cesium.Math.toDegrees(this.viewer.scene.camera.pitch);
            let roll = Cesium.Math.toDegrees(this.viewer.scene.camera.roll);

            console.log("camera: (%f, %f, %f, %f, %f, %f)", x, y, z, heading, pitch, roll)
        },


        // 添加兴趣点
        AddPin(typeid, id, qyxxindex, name, lat, lng){
                
            var littleimg = "./images/cyts/l"+ typeid +".png";
            console.log(littleimg);

            this.viewer.entities.add({
                id: id,
                name: name,
                typeid: typeid,
                qyxxindex: qyxxindex,
                position: Cesium.Cartesian3.fromDegrees(lat, lng),
                label: {
                    text: name,
                    scale: 0.6,
                    fillColor: Cesium.Color.WHITE,
                    pixelOffset: new Cesium.Cartesian2(0, -this.little_height-20), //偏移量
                },
                billboard: {                    
                    image: littleimg,
                    width: this.little_width,
                    height: this.little_height,
                    
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM,       //垂直位置
                    horizontalOrigin: Cesium.HorizontalOrigin.CENTER,   //水平位置
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
                }
            });
        },

        // 选择兴趣点
        SelectPin(typeid, id) {
            console.log("typeid="+typeid+"; id="+id)
            
            // 当前如果有选中，就还原
            if (this.currentid > 0) {
                var oldentity = this.viewer.entities.getById(this.currentid);

                var littleimg = "./images/cyts/l"+ oldentity.typeid +".png";

                oldentity.billboard.image = littleimg;
                oldentity.billboard.width = this.little_width;
                oldentity.billboard.height = this.little_height;
                
                oldentity.label.scale = 0.6;
                oldentity.label.fillColor = Cesium.Color.WHITE;
                oldentity.label.pixelOffset = new Cesium.Cartesian2(0, -this.little_height-20);
                oldentity.label.showBackground = false;

                this.currentid = 0;
            }

            // 新选中的放大
            var newentity = this.viewer.entities.getById(id);

            var bigimg = "./images/cyts/b"+ newentity.typeid +".png";

            newentity.billboard.image = bigimg;
            newentity.billboard.width = this.big_width;
            newentity.billboard.height = this.big_height;
                        
            newentity.label.scale = 0.8;
            newentity.label.fillColor = Cesium.Color.BLACK;
            newentity.label.pixelOffset = new Cesium.Cartesian2(0, -this.big_height-20);
            newentity.label.showBackground = true;
            newentity.label.backgroundColor = new Cesium.Color(0.5, 0.6, 1, 0.8),

            this.currentid = id;

            return newentity;

        },

    



    
        // 开始漫游
        startWalk() {
			console.log("---cyts---startWalk")
            
            // 企业连线
            let arrwalk = this.arrYQWalk[this.walk_yqid];
            for (var index=0; index<arrwalk.length-1; index++) {
                
                var longitude_0 = parseFloat(arrwalk[index].qy_longitude);
                var latitude_0 = parseFloat(arrwalk[index].qy_latitude);
                var longitude_1 = parseFloat(arrwalk[index+1].qy_longitude);
                var latitude_1 = parseFloat(arrwalk[index+1].qy_latitude);

                if (longitude_0==0 || latitude_0==0 || longitude_1==0 || latitude_1==0) {
                    continue;
                }

                var positions = [];
                positions.push(Cesium.Cartesian3.fromDegrees(longitude_0, latitude_0));
                positions.push(Cesium.Cartesian3.fromDegrees(longitude_1, latitude_1));
                MapPolyline(this.viewer, positions);
            }


            // 开始
            this.walk_index = 0;            
            this.WalkStep1();
        },
        
        // 停止漫游
        stopWalk() {
			console.log("---cyts---stopWalk")
            this.walk_index = 9999;            
        },

        
        flyToDuration(viewer, duration, longitude, latitude, height, heading, pitch, roll){
            
            // // 将经纬度转换为世界坐标           
            // var target = Cesium.Cartesian3.fromDegrees(longitude, latitude, height);
            // var offset = new Cesium.Cartesian3(-70.06, -68.64, 6.0908)
            // viewer.scene.camera.lookAt(target, offset);

            // 飞入
            viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(longitude, latitude, height),   
                orientation: {
                    heading: Cesium.Math.toRadians(heading),
                    pitch: Cesium.Math.toRadians(pitch),
                    roll: Cesium.Math.toRadians(roll)
                },
                complete: function callback() {
                    // 定位完成之后的回调函数
                },
                
                easingFunction: Cesium.EasingFunction.LINEAR_NONE,
                duration: duration // 设置飞行持续时间，默认会根据距离来计算
            });
        },

        // 漫游步骤1:飞入
        WalkStep1() {            
            console.log("======WalkStep1: "+ this.walk_index + " " + Date());
            
			this.$parent.closeVRVideo();
            this.audio.pause();


            let arrwalk = this.arrYQWalk[this.walk_yqid];

            console.log("arrwalk.length=" + arrwalk.length + "; this.walk_index=" + this.walk_index);
            if (this.walk_index >= arrwalk.length) {
                console.log("stop arrwalk.length=" + arrwalk.length + "; this.walk_index=" + this.walk_index);   
                return;
            }
            var index = this.walk_index++;

            var duration = parseFloat(arrwalk[index].flyduration);

            var strarrcamera = arrwalk[index].camera.split(",");
            var arrcamera = strarrcamera.map((value)=>{
                return parseFloat(value);
            })

            if (arrcamera.length < 6) {
                console.log("arrcamera.length("+ arrcamera.length +") < 6");
                return;
            }
            
            console.log("WalkFlyTo: %d, %f, %f, %f, %f, %f, %f", index, duration, arrcamera[0], arrcamera[1], arrcamera[2], arrcamera[3], arrcamera[4], arrcamera[5]);

            
            var cyid = parseInt(arrwalk[index].cyid);
            var qyxxid = parseInt(arrwalk[index].qyxxid);
            if (qyxxid > 0) {
                var newentity = this.SelectPin(cyid, qyxxid);	     

				this.$parent.selectQyxx(this.qyxxData[newentity.typeid-1][newentity.qyxxindex]);
            }	

            // MapFlyToDuration(this.viewer, duration, arrcamera[0], arrcamera[1], arrcamera[2], arrcamera[3], arrcamera[4], arrcamera[5]);
            this.flyToDuration(this.viewer, duration, arrcamera[0], arrcamera[1], arrcamera[2], arrcamera[3], arrcamera[4], arrcamera[5]);

            duration = duration + 2;
            
            setTimeout(() => {
                this.WalkStep2(this.walk_yqid, index);
            }, duration*1000);

        },


        // 漫游步骤2:播放
        WalkStep2(cyid, index) {            
            console.log("======WalkStep2: "+ this.walk_index + " " + Date() + cyid + " --- " + index);

            let arrwalk = this.arrYQWalk[cyid];
            var duration = parseFloat(arrwalk[index].duration);
            console.log("======WalkStep2: duration = "+ duration);

            // 播放声音
            // var voiceURL= "http://39.107.47.88:8015/smartgaoxin/data/路线/" + arrwalk[index].voice
            // var voiceURL= "http://127.0.0.1:8015/smartgaoxin/data/路线/" + arrwalk[index].voice
            var voiceURL= this.mediaSetting.prefix + arrwalk[index].voice
			console.log(voiceURL)
            this.audio.pause();
            this.audio.src = voiceURL;
            this.audio.play();



            // 播放视频
            // http://39.107.47.88:8015/smartgaoxin/data/%E8%B7%AF%E7%BA%BF/%E8%B7%AF%E7%BA%BF1/2-2.%E9%9B%AA%E6%B4%A5.mp4
			// var vrvadioURL= this.mediaSetting.prefix + this.qyxx.qyname + "/" + this.mediaSetting.vrvideo + "/" + this.qyxx.vrvideo
			// console.log(vrvadioURL)

            // var vrvadioURL= "http://39.107.47.88:8015/smartgaoxin/data/路线/" + arrwalk[index].vrvideo
            // var vrvadioURL= "http://127.0.0.1:8015/smartgaoxin/data/路线/" + arrwalk[index].vrvideo
            var vrvadioURL= this.mediaSetting.prefix + arrwalk[index].vrvideo
			console.log(vrvadioURL)
			this.$parent.openVRVideo(cyid, vrvadioURL, "true");
            
            duration = duration + 2;

            setTimeout(() => {
                this.WalkStep1();
            }, duration*1000);

        },


    }               

}



</script>


<style scoped>

</style>